import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import Axios from '../../../services/Axios';
import Endpoints from '../../../services/endpints';

const API_URL = '/api/data/ticket/TicketData';

const TicketData = [
  {
    Id: 1,
    ticketTitle: 'Sed ut perspiciatis unde omnis iste',
    ticketDescription:
      'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
    Status: 'Closed',
    Label: 'danger',
    AgentName: 'Liam',
    Date: '02-12-2021',
    deleted: false,
  },
  {
    Id: 2,
    ticketTitle: 'Consequuntur magni dolores eos qui ratione',
    ticketDescription:
      'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
    Status: 'Pending',
    Label: 'warning',
    AgentName: 'Steve',
    Date: '02-15-2020',
    deleted: false,
  },
];

const initialState = {
  tickets: [],
  transactions: [],
  payouts: [],
  currentFilter: 'total_tickets',
  ticketSearch: '',
};

export const TicketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    getTickets: (state, action) => {
      state.tickets = action.payload;
    },
    getTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    getPayouts: (state, action) => {
      state.payouts = action.payload;
    },
    changePayout: (state, action) => {
      console.log(action.payload, 'action.payload');
      state.payouts = state.payouts.map((payout) => {
        if (payout.id === action.payload.id) {
          return { ...payout, status: 'paid' };
        }
        return payout;
      });
    },
    setVisibilityFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
    SearchTicket: (state, action) => {
      state.ticketSearch = action.payload;
    },
    DeleteTicket: (state, action) => {
      // const index = state.tickets.findIndex((ticket) => ticket.Id === action.payload);
      state.tickets = [];
    },
  },
});

export const {
  getTickets,
  getTransactions,
  getPayouts,
  setVisibilityFilter,
  SearchTicket,
  DeleteTicket,
  changePayout,
} = TicketSlice.actions;

export const fetchTickets = (page) => async (dispatch) => {
  // debugger;

  try {
    const response = await Axios.get(Endpoints.seller.users, {
      params: {
        page,
      },
      headers: {
        Token: localStorage.getItem('token'),
      },
    });
    console.log(response?.data?.total_users?.data, 'rrrrrrrrrrrrrrrr');
    // dispatch(getTickets(TicketData));
    dispatch(getTickets(response?.data?.total_users));
  } catch (err) {
    // throw new Error(err);
    console.log(err);
  }
};

export const fetchTransactions = (page) => async (dispatch) => {
  try {
    const response = await Axios.get(Endpoints.seller.transactions, {
      params: {
        page,
      },
      headers: {
        Token: localStorage.getItem('token'),
      },
    });
    console.log(response, 'rrrrrrrrrrrrrrrr222');
    dispatch(getTransactions(response?.data?.transactions));
  } catch (err) {
    // throw new Error(err);
    console.log(err);
  }
};

export const fetchPayouts = (page) => async (dispatch) => {
  try {
    const response = await Axios.get(Endpoints.seller.payouts, {
      params: {
        page,
      },
      headers: {
        Token: localStorage.getItem('token'),
      },
    });
    // console.log(response);
    console.log(response, 'rrrrrrrrrrrrrrrr333');
    dispatch(getPayouts(response.data.payouts));
  } catch (err) {
    console.log(err);
  }
};

export default TicketSlice.reducer;
