import { createSlice } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Axios from '../../services/Axios';
import Endpoints from '../../services/endpints';

const initialState = {
  profile: null,
  isAuth: false,
  loading: true,
};

export const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfile: (state, { payload }) => {
      localStorage.setItem('token', payload?.user.token);
      axios.defaults.headers.common['Token'] = `${localStorage.getItem('token')}`;
      Axios.defaults.headers.common['Token'] = `${localStorage.getItem('token')}`;

      state.profile = payload;
      state.loading = false;
      state.isAuth = true;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    logOut: (state, { payload }) => {
      localStorage.removeItem('token');
      delete Axios.defaults.headers.common['Token'];
      delete axios.defaults.headers.common['Token'];
      state.isAuth = false;
      state.profile = null;
    },
  },
});

export const { getProfile, setLoading, logOut } = ProfileSlice.actions;

export const getCurrentUser = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await Axios.get(Endpoints.profile.get(localStorage.getItem('token')));
    console.log(res.data, 'dddddddd');
    dispatch(getProfile(res.data));
  } catch (err) {
    console.log(err, 'grom  getCurrentUserReducer');
    // alert('mddd');
    dispatch(setLoading(false));
    dispatch(logOut());
  }
};

export default ProfileSlice.reducer;
