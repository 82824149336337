const Endpoints = {
  auth: () => `/register`,
  signIn: () => '/login',
  resendEmailVerification: (id, code) => `/verify/email/${id}/${code}`,
  recoverPassword: () => `/restaurant/`,
  saveRestaurantInfo: (token) => `/getUser/${token}`,
  modifyLanding: () => `modify/landing`,
  getInvoice: (userId, Id) => `generate/invoice/${Id}/${userId}`,
  payoutStatusChange: (id) => `/admin/changeStatus/payout/${id}`,

  getRestaurantInfo: () => `/restaurant/`,
  profile: {
    get: (token) => `/getUser/${token}`,
    changePassword: '/profile/change/pass',
    updatePayoutInfo: '/payout/info',
    updateProfileInfo: '/profile/fullname/update',
  },
  landing: {
    get: (id) => `/landing/${id}`,
  },
  categories: {
    get: () => '/admin/categories',
  },
  imageUpload: () => '/image/uploader',
  specialOffer: {
    post: () => '/specialOffer',
    delete: () => '/specialOffer',
    getAll: () => '/specialOffer/getAll',
    changeStatus: () => '/specialOffer/changeStatus',
  },
  seller: {
    transactions: `/seller/transactions`,
    users: `/seller/users`,
    payout: `/seller/payout`,
    payouts: `/admin/payouts`,
    referrals: '/referrals',
    recoverPassword: '/reset/password',
    resetPassword: '/recover/password',
    getById: (id) => `/specificUser/${id}`,
  },
  chartData: '/seller/charts',
  plans: {
    get: '/plans',
    edit: (id) => `/edit/plan/${id}`,
  },
  downloads: {
    get: '/profile/download/links',
    change: (id) => `/admin/downloads/change/${id}`,
  },
};

export default Endpoints;
